import { useFormik } from "formik";
import React, { useState } from "react";
import { useEffect } from "react";
import { FaPlus } from "react-icons/fa";
import { toast } from "react-toastify";
import { httpReauest } from "../../../utils/httpRequest";
import * as Yup from "yup";
import { BASE_URL } from "../../../config";
import Loading from "../Loading";
import { FaTrash } from "react-icons/fa6";
import { Editor } from "@tinymce/tinymce-react";
import ReactQuill from "react-quill";

const CreateProductForm = () => {
  const [img, setImg] = useState();
  const [images, setImages] = useState([]);
  const [previews, setPreview] = useState([]);
  const [previewImg, setPreviewImg] = useState();
  const [categorys, setCategorys] = useState();
  const [subCategorys, setsubCategorys] = useState([]);
  const [loading, setloading] = useState();
  const [products, setproducts] = useState([
    {
      color: "",
      balance: "",
      price: "",
      special_price: "",
      storage: "",
      size: "",
    },
  ]);
  const [catalog, setCatalog] = useState();
  const [single, setsingle] = useState("notselect");
  const [video, setVideo] = useState();

  const VALUE_SIGN_VALIDATION = () => {
    return Yup.object({
      name: Yup.string().required("الزامی است"),
      url: Yup.string().required("الزامی است"),
    });
  };

  async function deleteImg(index) {
    setPreview((students) => students.filter((s, i) => i != index));
    setImages((students) => students.filter((s, i) => i != index));
  }

  const formik = useFormik({
    initialValues: {
      name: "",
      desc: "",
      category: "",
      subCategory: "",
      more_desc: "",
      url: "",
    },
    validationSchema: VALUE_SIGN_VALIDATION,
    onSubmit: async (values) => {
      setloading(true);
      const user = JSON.parse(localStorage.getItem("user"));
      const formdata = new FormData();

      formdata.append("name", values.name);
      formdata.append("url", values.url);
      formdata.append("category", values.category);
      formdata.append("subCategory", values.subCategory);
      formdata.append("desc", values.desc);
      formdata.append("more_desc", values.more_desc);

      formdata.append("products", JSON.stringify(products));

      if (images) {
        for (let index = 0; index < images.length; index++) {
          formdata.append("images", images[index], images[index]?.name);
        }
      }

      await fetch(BASE_URL + "/product", {
        method: "POST",
        body: formdata,
        headers: { "x-access-token": user.token },
      }).then(async (dat) => {
        const res = await dat.json();
        if (dat?.status == 201) {
          toast.success("محصول ساخته شد");
          setloading(false);
          formik.resetForm();
          setImg(null);
          setImages([]);
          setPreviewImg(null);
          setPreview([]);
          setCatalog(null);
          setVideo(null);
        } else {
          setloading(false);
          toast.error(res.message);
        }
      });
    },
  });

  useEffect(() => {
    getData();
  }, []);

  function handleImages(e) {
    const fils = e.target.files;
    if (fils) {
      for (let index = 0; index < fils?.length; index++) {
        if (fils[index].size > 2031517) {
          toast.error("حجم فایل باید کمتر از دو مگابایت باشد");
        } else {
          setImages((current) => [...current, fils[index]]);
          setPreviwImages(fils[index]);
        }
      }
    }
  }

  async function setPreviwImages(i) {
    const reader = new FileReader();
    reader.onloadend = function () {
      setPreview((current) => [...current, reader.result]);
    };
    reader.readAsDataURL(i);
  }

  async function setPreviwImage(imgInput) {
    const reader = new FileReader();
    reader.onloadend = function () {
      setPreviewImg(reader.result);
    };
    reader.readAsDataURL(imgInput);
  }

  async function getData() {
    await httpReauest("GET", "/category", {}, {}).then(({ data }) => {
      setCategorys(data?.data);
    });
  }

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
  ];

  function handleChange(value) {
    formik.setFieldValue("more_desc", value);
  }

  function handleDeleteCategory(_id) {
    const filtered = formik.values.category.filter((item) => item !== _id);
    formik.setFieldValue("category", filtered);
  }

  async function handleAddCategory(_id) {
    formik.setFieldValue("category", _id);
    const res = await httpReauest("GET", "/category/" + _id, {}, {});
    setsubCategorys(res.data.data.categories);
  }

  function handleChangePrice(params, index) {
    const value = params.target.value;
    const formattedValue = (
      Number(value.replace(/\D/g, "")) || ""
    ).toLocaleString();
    const newproducts = products.map((e, i) => {
      if (i === index) {
        return { ...e, price: formattedValue };
      } else {
        return { ...e };
      }
    });
    setproducts(newproducts);
  }
  function handleChangeSpecialPrice(params, index) {
    const value = params.target.value;
    const formattedValue = (
      Number(value.replace(/\D/g, "")) || ""
    ).toLocaleString();
    const newproducts = products.map((e, i) => {
      if (i === index) {
        return { ...e, special_price: formattedValue };
      } else {
        return { ...e };
      }
    });
    setproducts(newproducts);
  }

  function handleChangeProduct(value, fild, index) {
    setproducts((prevProducts) => {
      const updatedProducts = [...prevProducts];
      updatedProducts[index] = {
        ...updatedProducts[index],
        [fild]: value,
      };
      return updatedProducts;
    });
  }

  return (
    <div>
      <h2 className="text-[20px] mb-8 font-bold p-3">فرم ایجاد محصول</h2>
      <form
        onSubmit={formik.handleSubmit}
        className="grid grid-cols-1 lg:grid-cols-3 2xl:grid-cols-4 gap-6"
      >
        <>
          <span className="relative">
            <input
              id="name"
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="w-full form-brand h-[48px]"
            />
            {formik.errors.name && formik.touched.name && (
              <small style={{ color: "red", fontSize: "14px" }}>
                {formik.errors.name}
              </small>
            )}
            <p className="on-border font-bold">نام محصول*</p>
          </span>
          <span className="relative">
            <input
              id="url"
              name="url"
              value={formik.values.url}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="w-full form-brand h-[48px]"
            />
            <p className="on-border font-bold">url*</p>
          </span>

          <span className="relative">
            <select
              id="category"
              name="category"
              onChange={(e) => handleAddCategory(e.target.value)}
              className={` w-full form-brand h-[48px]`}
            >
              <option></option>
              {categorys?.map((e) => (
                <option value={e._id}>{e.name}</option>
              ))}
            </select>
            {formik.errors.category && formik.touched.category && (
              <small style={{ color: "red", fontSize: "14px" }}>
                {formik.errors.category}
              </small>
            )}
            <p className="on-border font-bold">دسته بندی *</p>
          </span>
          {subCategorys.length ? (
            <span className="relative">
              <select
                id="subCategory"
                name="subCategory"
                onChange={formik.handleChange}
                className={` w-full form-brand h-[48px]`}
              >
                <option></option>
                {subCategorys?.map((e) => (
                  <option value={e._id}>{e.name}</option>
                ))}
              </select>
              {formik.errors.subCategory && formik.touched.subCategory && (
                <small style={{ color: "red", fontSize: "14px" }}>
                  {formik.errors.subCategory}
                </small>
              )}
              <p className="on-border font-bold">دسته بندی داخلی*</p>
            </span>
          ) : null}
          <span className="relative">
            <textarea
              id="desc"
              name="desc"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={` w-full form-brand h-[78px]`}
            />
            <p className="on-border font-bold">توضیح کوتاه</p>
          </span>
          <>
            {products?.map((item, index) => (
              <span className="col-span-full grid grid-cols-1 lg:grid-cols-3 2xl:grid-cols-4 gap-6">
                <h3 className="col-span-full text-[22px] font-bold">
                  محصول {index + 1}
                </h3>
                <span className="relative">
                  <input
                    id={`color[${index}]`}
                    name={`color[${index}]`}
                    value={products[index].color}
                    onChange={(e) =>
                      handleChangeProduct(e.target.value, "color", index)
                    }
                    type="text"
                    className="w-full form-brand h-[48px]"
                  />
                  <p className="on-border font-bold">اسم رنگ*</p>
                </span>
                <span className="relative">
                  <input
                    id={`balance[${index}]`}
                    name={`balance[${index}]`}
                    value={products[index].balance}
                    onChange={(e) =>
                      handleChangeProduct(e.target.value, "balance", index)
                    }
                    onBlur={formik.handleBlur}
                    type="text"
                    className="w-full form-brand h-[48px]"
                  />
                  <p className="on-border font-bold">تعداد*</p>
                </span>
                <span className="relative">
                  <input
                    id={`storage[${index}]`}
                    name={`storage[${index}]`}
                    value={products[index].storage}
                    onChange={(e) =>
                      handleChangeProduct(e.target.value, "storage", index)
                    }
                    onBlur={formik.handleBlur}
                    type="text"
                    className="w-full form-brand h-[48px]"
                  />
                  <p className="on-border font-bold">حافظه</p>
                </span>
                <span className="relative">
                  <input
                    id={`size[${index}]`}
                    name={`size[${index}]`}
                    value={products[index].size}
                    onChange={(e) =>
                      handleChangeProduct(e.target.value, "size", index)
                    }
                    onBlur={formik.handleBlur}
                    type="text"
                    className="w-full form-brand h-[48px]"
                  />
                  <p className="on-border font-bold">سایز</p>
                </span>
                <span className="relative">
                  <input
                    id={`price[${index}]`}
                    name={`price[${index}]`}
                    value={products[index].price}
                    onChange={(e) => handleChangePrice(e, index)}
                    className="w-full form-brand h-[48px]"
                  />
                  <p className="absolute translate-y-[-50%] top-[24px] left-4">
                    تومان
                  </p>
                  <p className="on-border font-bold">قیمت محصول*</p>
                </span>
                <span className="relative">
                  <input
                    id={`special_price[${index}]`}
                    name={`special_price[${index}]`}
                    value={products[index].special_price}
                    onChange={(e) => handleChangeSpecialPrice(e, index)}
                    className="w-full form-brand h-[48px]"
                  />

                  <p className="absolute translate-y-[-50%] top-[24px] left-4">
                    تومان
                  </p>
                  <p className="on-border font-bold">
                    قیمت با تخفیف(در صورتی که تخفیف دارد)
                  </p>
                </span>
              </span>
            ))}
            <span className="flex col-span-full gap-2 items-center">
              <FaPlus
                className="cursor-pointer"
                onClick={() => {
                  setproducts((cur) => [
                    ...cur,
                    {
                      color: "",
                      balance: "",
                      price: "",
                      special_price: "",
                      storage: "",
                      size: "",
                    },
                  ]);
                }}
                color="black"
                size={32}
              />
              {products?.length > 1 && (
                <FaTrash
                  onClick={() =>
                    setproducts((prevProducts) =>
                      prevProducts.filter((_, i) => i !== products.length - 1)
                    )
                  }
                  className="cursor-pointer"
                  color="red"
                  size={32}
                />
              )}
            </span>
          </>

          <span dir="ltr" className="col-span-full">
            <h2 className="title text-right">:توضیح</h2>
            <ReactQuill
              value={formik.values.more_desc}
              modules={{
                toolbar: [
                  [{ header: [1, 2, false], font: ["Opens Sans"] }, {}],
                  ["bold", "italic", "underline", "strike", "blockquote"],
                  [
                    { list: "ordered" },
                    { list: "bullet" },
                    { indent: "-1" },
                    { indent: "+1" },
                  ],
                  ["link", "image", "code"],
                  ["clean"],
                ],
              }}
              formats={formats}
              onChange={handleChange}
            />
          </span>

          <div className="relative col-span-full w-full border-dashed border rounded-[10px] p-3 min-h-[340px]  border-[#444]">
            {previews?.length > 0 ? (
              <div
                className="p-3"
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "15px",
                }}
              >
                {previews &&
                  previews.map((src, i) => {
                    return (
                      <span className="relative">
                        <img
                          src={src}
                          style={{
                            width: "150px",
                            height: "150px",
                            objectFit: "contain",
                          }}
                          alt=""
                        />
                        <FaTrash
                          color="red"
                          className="top-0 right-0 absolute"
                          onClick={() => deleteImg(i)}
                        />
                      </span>
                    );
                  })}
                <label htmlFor="images" className="cursor-pointer">
                  <FaPlus size={50} />
                </label>
              </div>
            ) : (
              <label htmlFor="images" className="cursor-pointer">
                <FaPlus className="center" size={50} />
              </label>
            )}

            <p className="on-border font-bold">تصاویر</p>
          </div>
          <input
            onChange={(e) => {
              handleImages(e);
            }}
            type="file"
            className="hidden"
            id="images"
            accept="image/png, image/jpg, image/jpeg"
          />
          {loading ? (
            <Loading />
          ) : (
            <button type="submit" className="btn-brand">
              ارسال
            </button>
          )}
        </>
      </form>
    </div>
  );
};

export default CreateProductForm;

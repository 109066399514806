import React, { useEffect, useState } from "react";
import { httpReauest } from "../../../utils/httpRequest";
import { BASE_URL, CDN_BASE_URL } from "../../../config";
import * as Yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { FaPlus } from "react-icons/fa";
import Loading from "../Loading";

const EditCategoryForm = () => {
  const [brands, setBrands] = useState();
  const [img, setImg] = useState();
  const [img2, setImg2] = useState();
  const [previewImg, setPreviewImg] = useState();
  const [previewImg2, setPreviewImg2] = useState();
  const [loading, setloading] = useState();
  const [selectedBrand, setselectedBrand] = useState();

  useEffect(() => {
    stayLogin();
  }, []);

  async function stayLogin() {
    await httpReauest("GET", "/category", {}, {}).then(({ data }) => {
      setBrands(data?.data);
    });
  }

  const VALUE_SIGN_VALIDATION = () => {
    return Yup.object({
      name: Yup.string().required("الزامی است"),
      url: Yup.string().required("الزامی است"),
    });
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      url: "",
    },
    validationSchema: VALUE_SIGN_VALIDATION,
    onSubmit: async (values) => {
      setloading(true);
      const user = JSON.parse(localStorage.getItem("user"));
      const formdata = new FormData();
      if (img) {
        formdata.append("img", img, img?.name);
      }
      formdata.append("name", values.name);
      formdata.append("url", values.url);

      await fetch(BASE_URL + "/category/" + selectedBrand?._id, {
        method: "PUT",
        body: formdata,
        headers: { "x-access-token": user.token },
      })
        .then((dat) => {
          if (dat?.status == 201) {
            toast.success("دسته بندی تغییر یافت");
            setloading(false);
            formik.resetForm();
            setImg(null);
            setImg2(null);
            setPreviewImg(null);
            setPreviewImg2(null);
          }
        })
        .catch((err) => {
          setloading(false);
          toast.error(err.response?.data?.message);
        });
    },
  });

  async function handleDelete(params) {
    const user = JSON.parse(localStorage.getItem("user"));
    await fetch(BASE_URL + "/category/" + selectedBrand?._id, {
      method: "DELETE",
      headers: { "x-access-token": user.token },
    })
      .then((dat) => {
        if (dat?.status == 201) {
          toast.success("دسته بندی حذف شد");
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        }
      })
      .catch((err) => {
        toast.error(err.response?.data?.message);
      });
  }

  async function handleChange(e) {
    const newBrand = await brands.find((value) => value._id == e.target.value);

    setselectedBrand(newBrand);
    formik.setFieldValue("name", newBrand.name);
    formik.setFieldValue("url", newBrand.url);

    setPreviewImg(CDN_BASE_URL + newBrand?.img);
  }

  function handleImg(e) {
    const fils = e.target.files[0];
    if (fils.size > 2031517) {
      toast.error("حجم فایل باید کمتر از دو مگابایت باشد");
    } else {
      setImg(fils);
      setPreviwImage(fils);
    }
  }
  function handleImg2(e) {
    const fils = e.target.files[0];
    if (fils.size > 2031517) {
      toast.error("حجم فایل باید کمتر از دو مگابایت باشد");
    } else {
      setImg2(fils);
      setPreviwImage2(fils);
    }
  }

  async function setPreviwImage(imgInput) {
    const reader = new FileReader();
    reader.onloadend = function () {
      setPreviewImg(reader.result);
    };
    reader.readAsDataURL(imgInput);
  }

  async function setPreviwImage2(imgInput) {
    const reader = new FileReader();
    reader.onloadend = function () {
      setPreviewImg2(reader.result);
    };
    reader.readAsDataURL(imgInput);
  }

  return (
    <div>
      <h2 className="text-[20px] mb-8 font-bold p-3">فرم تغییر دسته بندی</h2>
      <form
        onSubmit={formik.handleSubmit}
        className="grid grid-cols-1 lg:grid-cols-3 2xl:grid-cols-4 gap-6"
      >
        <span className="relative">
          <select
            id="brand"
            name="brand"
            onChange={handleChange}
            className={` w-full form-brand h-[48px]`}
          >
            <option></option>
            {brands?.map((e) => {
              if (!e.end) {
                return <option value={e._id}>{e.name}</option>;
              }
            })}
          </select>
          <p className="on-border font-bold">انتخاب دسته بندی*</p>
        </span>
        {selectedBrand && (
          <>
            <span className="relative">
              <input
                placeholder="مثلا : فوتون 450"
                id="name"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="w-full form-brand h-[48px]"
              />
              {formik.errors.name && formik.touched.name && (
                <small style={{ color: "red", fontSize: "14px" }}>
                  {formik.errors.name}
                </small>
              )}
              <p className="on-border font-bold">نام دسته بندی*</p>
            </span>
            <span className="relative">
              <input
                placeholder="مثلا : فوتون 450"
                id="url"
                name="url"
                value={formik.values.url}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="w-full form-brand h-[48px]"
              />
              {formik.errors.url && formik.touched.url && (
                <small style={{ color: "red", fontSize: "14px" }}>
                  {formik.errors.url}
                </small>
              )}
              <p className="on-border font-bold">url*</p>
            </span>

            <label
              htmlFor="img"
              className="relative w-full border-dashed border rounded-[10px] p-3 h-[200px] cursor-pointer border-[#444]"
            >
              {previewImg ? (
                <img
                  src={previewImg}
                  className="w-full h-full object-contain"
                  alt=""
                />
              ) : (
                <FaPlus className="center" size={30} />
              )}
              <p className="on-border font-bold">تصویر</p>
            </label>

            <input
              onChange={(e) => {
                handleImg(e);
              }}
              type="file"
              className="hidden"
              id="img"
              accept="image/png, image/jpg, image/jpeg"
            />
            <span className="col-span-full flex gap-4">
              {loading ? (
                <Loading />
              ) : (
                <>
                  <button className="btn-brand">تغییر دسته بندی</button>
                  <button
                    type="button"
                    onClick={handleDelete}
                    className=" btn-brand-outline"
                  >
                    {" "}
                    حذف دسته بندی
                  </button>
                </>
              )}
            </span>
          </>
        )}
      </form>
    </div>
  );
};

export default EditCategoryForm;
